import React, { useEffect } from "react"
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import { connect } from 'react-redux'
import { toast } from "react-toastify"
import lokkLogo from "./../assets/images/deca_logo.svg"
import axios from 'axios'
import { QRCode } from 'react-qrcode-logo'
import Keyboard from "../components/Keyboard"
import scssVars from "./../assets/sass/vars.scss"
import { changeLanguage } from './../redux/actions/i18n'
import { withTranslation } from 'react-i18next'
import {
    Unlock,
} from 'react-feather';



class Delivery extends React.Component {
    state = {
        maxPinLength: 6,
        qrCode: process.env.REACT_APP_QRCODE_VALUE,
        pin: '',
        savedPin: '',
        inputRegex: new RegExp('^[0-9]+$'),
        keys: [],
        animation: '',
        showQrcode: false,
        showKeyboard: true,
        isSubmitButtonDisabled: true,
        currentDoor: '',
        lang: this.props.lang,
        showOpenDoorModal: false,
        showSuccessOpenDoorModal: false,
    }

    componentDidMount() {
        this.generateKeys()
        this.props.i18n.changeLanguage(this.props.lang)
    }

    changeLanguage = (e, lang) => {
        e.stopPropagation()
        this.props.dispatch(changeLanguage(lang)).then(() => {
            this.props.i18n.changeLanguage(lang)
            toast.success(this.props.t("toast.change-lang"), { toastId: lang, position: toast.POSITION.TOP_CENTER },)
        })
    }



    resetActivityTimer = () => {
        var self = this
        clearTimeout(this.activityTimer)
        this.setState({ showOpenDoorModal: false })
        this.setState({ showSuccessOpenDoorModal: false })
        this.activityTimer = setTimeout(
            function () {
                self.setState({
                    pin: '',
                    isSubmitButtonDisabled: true
                })
                //reset language to pt
                if (!self.state.showOpenDoorModal) {
                    self.props.dispatch(changeLanguage('pt')).then(() => {
                        self.props.i18n.changeLanguage('pt')
                    })
                }
                self.props.setKeyPressed(0)
            }, process.env.REACT_APP_INACTIVITY_TIMER * 1000)
    }

    resetActivityTimerSuccessOpenDoorModal = () => {
        var self = this
        clearTimeout(this.activityTimer)
        this.activityTimer = setTimeout(
            function () {
                self.setState({
                    showSuccessOpenDoorModal: false
                })
                //reset language to pt
                if (!self.state.showSuccessOpenDoorModal) {
                    self.props.dispatch(changeLanguage('pt')).then(() => {
                        self.props.i18n.changeLanguage('pt')
                    })
                }
                self.props.setKeyPressed(0)
            }, 5000)
    }

    cleanPin = () => {
        this.setState({
            pin: '',
            isSubmitButtonDisabled: true,
        })
        this.props.setKeyPressed(0)
    }

    generateKeys = () => {
        var keys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        //shuffles keys into a random order
        // for (let i = keys.length - 1; i > 0; i--) {
        //     const j = Math.floor(Math.random() * i)
        //     const temp = keys[i]
        //     keys[i] = keys[j]
        //     keys[j] = temp
        // }

        keys.push('⇦')
        keys.splice(keys.length - 2, 0, "#")
        this.setState({ keys: keys })
    }

    handleClick = (val) => {
        this.resetActivityTimer()
        if (val === '#') {
            this.cleanPin()
        } else if (val === '⇦') {
            const newPin = this.state.pin.slice(0, -1)
            if (this.state.pin.length > 0) {
                this.props.setKeyPressed(this.props.keyPressed - 1)
            }
            this.setState({ pin: newPin }, () => {
                this.checkPinLength()
            })
        } else {
            if (this.state.pin.length < 6) {
                this.props.setKeyPressed(this.props.keyPressed + 1)
                const newPin = this.state.pin.concat(val)
                this.setState({ pin: newPin }, () => {
                    this.checkPinLength()
                })
            }
        }
    }

    checkPinLength = () => {
        if (this.state.pin.length < 6) {
            this.setState({ isSubmitButtonDisabled: true })
        } else {
            this.setState({ isSubmitButtonDisabled: false })
        }
    }

    setAnimation = name => {
        this.setState({ animation: name })
        setTimeout(function () {
            this.setState({ animation: '' })
        }.bind(this), 1000)
    }

    modalCloseTimer = () => {
        setTimeout(function () {
            this.setState({ showOpenDoorModal: false })
        }.bind(this), 10000)
    }

    verifyPin = () => {
        this.resetActivityTimer()
        if (!this.state.isSubmitButtonDisabled) {
            this.setState({ isSubmitButtonDisabled: true })
            var self = this
            let pin = this.state.pin
            this.setState({ pin: '', savedPin: pin })
            axios.get(`${process.env.REACT_APP_API_URL}api/delivery/verifyPickUpPackageWithPin?pin=${pin}`, {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
                }
            })
                .then(res => {
                    self.setState({ isSubmitButtonDisabled: false })
                    if (res.data.ResultCode === 1) {
                        this.setState({ currentDoor: res.data.Data.assignedLocker.lockerMetraId })
                        self.toggleOpenDoorModal(true)
                        self.modalCloseTimer()
                        self.setAnimation('success')
                    } else {
                        toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                        self.setAnimation('error')
                    }
                })
                .catch((err) => {
                    self.setState({ isSubmitButtonDisabled: false })
                    self.setAnimation('error')
                    toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                })
        }
    }

    onSubmit = () => {
        this.resetActivityTimer()
        if (!this.state.isSubmitButtonDisabled) {
            this.setState({ isSubmitButtonDisabled: true })
            var self = this
            let pin = this.state.savedPin
            this.setState({ savedPin: '' })
            axios.get(`${process.env.REACT_APP_API_URL}api/delivery/pickUpPackageWithPin?pin=${pin}`, {
                headers: {
                    Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
                }
            })
                .then(res => {
                    self.setState({ isSubmitButtonDisabled: false })
                    if (res.data.ResultCode === 1) {
                        this.setState({ currentDoor: res.data.Data.assignedLocker.lockerMetraId })
                        self.setAnimation('success')
                        self.toggleOpenSuccessDoorModal(true)
                        //reset language to pt
                        self.props.dispatch(changeLanguage('pt')).then(() => {
                            self.props.i18n.changeLanguage('pt')
                        })
                    } else {
                        toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                        self.setAnimation('error')
                        //reset language to pt
                        self.props.dispatch(changeLanguage('pt')).then(() => {
                            self.props.i18n.changeLanguage('pt')
                        })
                    }
                })
                .catch((err) => {
                    self.setState({ isSubmitButtonDisabled: false })
                    self.setAnimation('error')
                    toast.error(this.props.t("toast.error-pin-sub"), { toastId: 'error-pin-sub', position: toast.POSITION.TOP_CENTER },)
                    //reset language to pt
                    self.props.dispatch(changeLanguage('pt')).then(() => {
                        self.props.i18n.changeLanguage('pt')
                    })
                })
        }
    }

    toggleOpenDoorModal = (val) => {
        this.resetActivityTimer()
        this.setState({ showOpenDoorModal: val })
    }

    toggleOpenSuccessDoorModal = (val) => {
        this.resetActivityTimerSuccessOpenDoorModal()
        this.setState({ showSuccessOpenDoorModal: val })
    }

    cancel = () => {
        this.setState({ showOpenDoorModal: false })
    }

    render() {

        const { t } = this.props

        const { showQrcode, showKeyboard, logo, logoFull, qrCode, showOpenDoorModal, isSubmitButtonDisabled,showSuccessOpenDoorModal } = this.state

        const pinInputs = []

        for (var i = 0; i < this.state.maxPinLength; i++) {
            pinInputs.push(<Col className="col-2 pin-key" key={i}>
                {this.state.pin[i] && <div><div className="pin-div">{this.state.pin[i]}</div><div className="float"></div></div>}
            </Col>)
        }

        const openDoorModal = (
            <Modal
                backdrop="static"
                isOpen={showOpenDoorModal}
                toggle={() => this.toggleOpenDoorModal(!this.state.showOpenDoorModal)}
                centered
                size="xl"
            >
                <ModalBody className="modal-body modal-size py-5">
                    <div className="modal-door-wrapper d-flex align-items-center justify-content-center">
                        <span className="modal-door">{this.state.currentDoor}</span>
                    </div>
                    <p className="modal-text-confirmed m-0"><strong>{t("delivery.codeConfirmed")}</strong></p>
                    <p className="modal-text-doorOpen p-0 m-0">{t("delivery.doorHasBeenOpen")}<strong className="ps-2 p-0"><p className="p-0 m-0 modal-text-doorOpen">{t('delivery.door')} {this.state.currentDoor}.</p></strong></p>
                    <p className="modal-text-retrieve">{t("delivery.pleaseRetrieve")}</p>
                    <Row className="justify-content-center">
                        <Button
                            color="primary"
                            className="modal-btn mt-5"
                            onClick={this.onSubmit}>
                            <Unlock size={30} className="me-3" />
                            {t("button.openDoor")}
                        </Button>
                        {/*<Button
                          color="danger"
                          className="modal-btn m-3"
                          onClick={() => this.cancel()}>
                          {t("button.cancel")}
                      </Button>*/}
                    </Row>
                </ModalBody>
            </Modal>
        )

        const successOpenDoorModal = (
            <Modal
                backdrop="static"
                isOpen={showSuccessOpenDoorModal}
                toggle={() => this.toggleOpenSuccessDoorModal(!this.state.showSuccessOpenDoorModal)}
                centered
                size="xl"
            >
                <ModalBody className="modal-body-successOpenDoor modal-size">
                    <div className="modal-door-wrapper-successOpenDoor d-flex align-items-center justify-content-center">
                        <span className="modal-door-successOpenDoor">{this.state.currentDoor}</span>
                    </div>
                    <p className="modal-text-successOpenDoor m-0"><strong>{t('delivery.successDoorOpen')}</strong></p>
                </ModalBody>
            </Modal>
        ) 

        return (
            <>
                {openDoorModal}
                {successOpenDoorModal}
                <div className="office-container container-fluid">

                    <Row className="lang-row">
                        <button className={"pt " + (this.props.lang === 'pt' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'pt')}>PT</button>
                        <button className={"en " + (this.props.lang === 'en' ? "active" : "")} onClick={(e) => this.changeLanguage(e, 'en')}>EN</button>
                    </Row>
                    <Row className="logo-row">
                        <a href={process.env.REACT_APP_URL_HOME}>
                            <img src={lokkLogo} className="logo-img" />
                        </a>
                    </Row>
                    <Row className="pin-submit-office">
                        <Row className="p-0 m-0 help-section">
                            <div className="container-fluid help-container">
                                <Row className="section-title">
                                    <h2>{t("delivery.title")}</h2>
                                </Row>
                                <Row className="qr-div justify-content-center">
                                    <QRCode
                                        value={process.env.REACT_APP_QRCODE_VALUE}
                                        fgColor={scssVars.qrcolor}
                                        size="200" />
                                </Row>
                                <Row className="subtitle-row">
                                    <p>
                                        {t("delivery.sub-title.text-1")}
                                        <br /><span>{t("delivery.sub-title.or")}</span><br />
                                        {t("delivery.sub-title.text-2")}
                                    </p>
                                </Row>
                            </div>
                        </Row>
                        <Row className="m-0 keyboard-col">
                            <div className="keyboard-wrapper">
                                <Row className="justify-content-start align-items-center">
                                    <Row
                                        className={`input-wrapper ${this.state.animation}`}
                                    >
                                        {pinInputs}
                                    </Row>
                                </Row>
                                <Row className="justify-content-start keys-row">
                                    <Col>
                                        <Keyboard
                                            handleClick={this.handleClick}
                                            keys={this.state.keys}
                                            isSubmitting={this.state.isSubmitting}
                                            animation={this.state.animation} />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center confirm-button-row">
                                    <Button
                                        disabled={isSubmitButtonDisabled}
                                        className="confirm-btn"
                                        onClick={() => this.verifyPin()}>
                                        {t("button.btn-confirm")}
                                    </Button>
                                </Row>
                            </div>
                        </Row>
                    </Row>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        lang: state.i18n.language
    }
}

export default connect(mapStateToProps)(withTranslation('common')(Delivery))
